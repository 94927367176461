import React, { useEffect, useState } from "react";
import api from "./api";



function App() {
  const [agenda, setAgenda] = useState([]);

  useEffect(()=>{
    api.get('agenda/filter/all').then(({data})=>{
      setAgenda(data);
    })
    console.log(agenda);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
     <h1>Agenda</h1>
    </div>
  );
}

export default App;
